import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
	// background-color: #000;
	// background-image: linear-gradient(
	// 		to top,
	// 		rgba(0, 0, 0, 0.2),
	// 		rgba(0, 0, 0, 1)
	// 	),
	// 	${({ image }) => (image ? `url(${image})` : "none")};
	// background-image: ${({ image }) => (image ? `url(${image})` : "none")};
	// background-size: cover;
	// background-position: center;
	// width: 100%;
	// display: flex;
	// flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
	height: 8px;
	width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
	height: 16px;
	width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
	height: 24px;
	width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
	height: 32px;
	width: 32px;
`;

// Used for providing a wrapper around a component
export const ContainerUpp = styled.div`
	background-color: ${({ test }) => (test ? "pink" : "none")};
	background-image: ${({ image }) => (image ? `url(${image})` : "none")};
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	background-position: center;
	background-attachment: fixed;
	// display: flex;
	// justify-content: center;
	// align-items: center;
`;

export const Container = styled.div`
	// display: flex;
	// justify-content: center;
	// align-items: center;
	// background-color: ${({ test }) => (test ? "pink" : "none")};
	// background-image: ${({ image }) => (image ? `url(${image})` : "none")};
	// background-size: cover;
	// background-repeat: no-repeat;
	// width: 100%;
	// height: 100vh;
	// background-position: center;
	// background-attachment: fixed;
`;

export const TextTitle = styled.p`
	color: var(--primary-text);
	font-size: 22px;
	font-weight: 500;
	line-height: 1.6;
`;

export const TextSubTitle = styled.p`
	color: var(--primary-text);
	font-size: 18px;
	line-height: 1.6;
`;

export const TextDescription = styled.p`
	color: var(--primary-text);
	font-size: 16px;
	line-height: 1.6;
`;

export const StyledClickable = styled.div`
	:active {
		opacity: 0.6;
	}
`;
