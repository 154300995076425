export const faqData = [
	{
		t: (
			<>
				🇺🇸 WHAT WALLET CAN I CONNECT TO MINT A LLAMÆMPIRE NFT? <br></br>
				🇧🇷 QUAL CARTEIRA POSSO CONECTAR PARA MINTAR NFTS DO LLAMÆMPIRE?
			</>
		),
		p: (
			<>
				You can ONLY connect MetaMask. If you don’t have one yet, you can download at :{" "}
				<a target="blank" className="text-blue" href="https://metamask.io/">
					https://metamask.io
				</a>

				<br></br>
				<br></br>
				Você só poderá conectar a MetaMask.  Se você ainda não tem uma, pode fazer o download em
				<a target="blank" className="text-blue" href="https://metamask.io/">
					https://metamask.io
				</a>
			</>
		),
	},
	{
		t: (
			<>
				🇺🇸 HOW CAN I ADD POLYGON NETWORK ON METAMASK? 
				<br></br>
				🇧🇷 COMO POSSO ADICIONAR O POLYGON NETWORK NA METAMASK?
			</>
		),
		p: (
			<>
				In order to mint a LLAMÆMPIRE NFT you have to make sure that you have the Polygon Network added in your MetaMask and that your $MATIC is there and not on the Ethereum Network. Here is a tutorial on how to add it:{" "}
				<a target="blank" className="text-blue" href="https://academy.binance.com/sv/articles/how-to-add-polygon-to-metamask">
					Click Here
				</a>
				<br></br>
				<br></br>
				Para mintar NFTs do LLAMÆMPIRE, você precisa ter a Polygon Network adicionada em sua MetaMask e confirmar que seu $MATIC estará lá e não na Ethereum Network.  Aqui está um tutorial sobre como adicioná-lo:
				<a target="blank" className="text-blue" href="https://academy.binance.com/sv/articles/how-to-add-polygon-to-metamask">
					Clica aqui
				</a>
			</>
		),
	},
	{
		t: (
			<>
				🇺🇸 HOW MUCH IT COSTS TO MINT A LLAMÆMPIRE NFT? 
				<br></br>
				🇧🇷 QUANTO CUSTA O MINT DO NFT LLAMÆMPIRE?
			</>
		),
		p: (
			<>
				50 $MATIC for people that are in the whitelist (March 16th) and 60 $MATIC on Public Sale (March 17th). During pre-sale you can mint up to 3 NFTs and during public sale up to 10 NFTs.
				<br></br>
				<br></br>
				50 $MATIC para pessoas que estão na whitelist (16 de março) e 60 $MATIC na venda pública (17 de março).  Durante a pré-venda você pode mintar até 3 NFTs e durante a venda pública até 10 NFTs. Tenha um pouco de $MATIC a mais NA carteira para as taxas de gás.
			</>
		),
	},
	{
		t: (
			<>
				🇺🇸 HOW CAN I BUY $MATIC FOR THE MINT? 
				<br></br>
				🇧🇷 COMO POSSO COMPRAR $MATIC PARA O MINT?
			</>
		),
		p: (
			<>
				You can buy $MATIC through a crypto exchange like <a  target="blank" className="text-blue" href="https://www.binance.com/en">Binance</a> or <a  target="blank" className="text-blue" href="https://crypto.com/">Crypto.com</a>. Lately, Crypto.com has been offering a better Polygon Network stability compared to Binance. In both, just create an account and buy your $MATIC by bank deposit, bank transfer or credit card. (OBS.: Be aware of the different deadlines for receipt of each modality!) After purchasing them, just transfer the desired amount to your MetaMask wallet address (OBS.: REMEMBER TO CHOOSE THE POLYGON NETWORK AT THE MOMENT OF THE TRANSFER!!)
				<br></br>
				<br></br>
				Você pode comprar $MATIC por alguma crypto-corretora como <a  target="blank" className="text-blue" href="https://www.binance.com/en">Binance</a> ou <a  target="blank" className="text-blue" href="https://crypto.com/">Crypto.com</a>.. Ultimamente a crypto.com tem oferecido um melhor ambiente e estabilidade comparada à Binance. Em ambas, basta criar uma conta e comprar seus $MATIC por depósito bancário, boleto, pix ou cartão de crédito. (OBS.: Fique atento com os diferentes prazos de recebimento de cada modalidade!) Após compra-los, basta apenas transferir a quantia desejada para o seu endereço de carteira da MetaMask (OBS.: FIQUE ATENTO PARA ESCOLHER A REDE POLYGON NO MOMENTO DA TRANSFERÊNCIA!!)
			</>
		),
	},
	{
		t: (
			<>
				🇺🇸 WHERE CAN I SEE THE NFT THAT I MINTED? 
				<br></br>
				🇧🇷 ONDE POSSO VER O NFT QUE MINTEI?
			</>
		),
		p: (
			<>
				You can check on OpenSea ( 
				<a  target="blank" className="text-blue" href="https://opensea.io/">https://opensea.io/</a>
				) 
				which is the marketplace that we chose. Go to the hidden tab because since we use Polygon, your NFT may go there first.
				<br></br>
				<br></br>
				Você pode conferir no OpenSea ( 
				<a  target="blank" className="text-blue" href="https://opensea.io/">
				https://opensea.io/
				</a>
				) 
				que é o marketplace que escolhemos.  Verifique a hidden tab porque como usamos o Polygon, seu NFT pode ir primeiro para lá.
			</>
		),
	}
];
